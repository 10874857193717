var Isotope = require('isotope-layout');
var imagesLoaded = require('imagesloaded');

function homeModul()
{
    'use strict';

    var iso;
    var project_grid = $(".project_grid");
    var timeout;
    var urlParamater;
    var columnNum = 1;
    var columnWidth = 0;
    var IS_TOUCH = isTouchDevice();

    var selectedFilter = "";


    // -------------------------------------------
    this.init = function()
    {
        initIsotope();

        initListener();

        checkDeeplink();
    };

    // -------------------------------------------
    function initIsotope()
    {
        iso = new Isotope( '.project_grid', {
            itemSelector: '.project_grid-item',
            layoutMode: 'packery',
            masonry: {
                columnWidth: columnWidth,
                isFitWidth: true
            }
        });

        imagesLoaded( '.project_grid', function() {
            $(window).resize();
        });


        timeout = setTimeout(function()
        {
             $(window).resize();

        }, 1000);


        if(IS_TOUCH == true)
        {
            $(".touch_link").addClass("show");
        }
    }



    // -------------------------------------------
    function initListener()
    {
        $(".project_filter_button").click(function(e)
        {
            e.preventDefault();

            // highlight
            $(".project_filter_button").removeClass("active");
            $(this).addClass("active");

            // filter
            var filterValue = $( this ).attr('data-filter');
            selectedFilter = filterValue;


            if(filterValue != "")
            {
                iso.arrange({ filter: "."+filterValue });
            }
            else
            {
                iso.arrange({ filter: filterValue });
            }

            timeout = setTimeout(function()
            {
                $(window).resize();

            }, 100);



        });

        // grid item
        $(".project_grid-item").mouseover(function()
        {
            if(IS_TOUCH == false)
            {
                $(this).addClass("project_grid-item_hover");
            }
        });
        $(".project_grid-item").mouseout(function()
        {
            $(this).removeClass("project_grid-item_hover");
        });
        $(".project_grid-item").click(function()
        {
            var url = $(this).data("url");
            console.log(url);


            if(IS_TOUCH == false )
            {

                if(selectedFilter != "")
                {
                    location.href=url + "?f=" + selectedFilter;
                }
                else
                {
                    location.href=url;
                }

            }
            else if($(this).hasClass("project_grid-item_hover"))
            {
                if(selectedFilter != "")
                {
                    location.href=url + "?f=" + selectedFilter;
                }
                else
                {
                    location.href=url;
                }
            }
            else
            {
                $(".project_grid-item").removeClass("project_grid-item_hover");

                $(this).addClass("project_grid-item_hover");
            }
        });


    }

    // -------------------------------------------
    function checkDeeplink()
    {
        urlParamater = getUrlParameter("f");

        //console.log("checkDeeplink() " + urlParamater);

        if(urlParamater != undefined && urlParamater != "")
        {
            $(".project_sub_menu").toggleClass("show");
            $(".wrapper").toggleClass("move");

            $(".nav-link-project").addClass("active");

            iso.arrange({ filter: "."+urlParamater });
        }
        else if(urlParamater != undefined && urlParamater == "")
        {
            $(".project_sub_menu").toggleClass("show");
            $(".wrapper").toggleClass("move");

            $(".nav-link-project").addClass("active");
        }
        else if(urlParamater != "")
        {
            iso.arrange({ filter: urlParamater });
        }

        // reset highlight
        $(".project_filter_button").removeClass("active");

        // set highlight
        var filterValue = "";
        if(urlParamater != "" && urlParamater != undefined)
        {
            filterValue = String( urlParamater );

            selectedFilter = filterValue;
        }

        //console.log("filterValue = " + filterValue);

        $("[data-filter='"+filterValue+"']").addClass('active');
    }

    // -------------------------------------------
    $(window).on('resize', function()
    {
        //console.log("resize");

        var winWidth = $(window).width();
        var winHeight = $(window).height();

        $(".project_grid").css("width", winWidth+"px");

        var w = project_grid.width() - 1;

        if (w > 1040)     { columnNum  = 8; }
        else if (w > 850) { columnNum  = 6; }
        else if (w > 768) { columnNum  = 6; }
        else if (w > 480) { columnNum  = 4; }
        else if (w > 300) { columnNum  = 4; }
        columnWidth = Math.floor((w)/columnNum);


        $(".debug").text(columnNum +  " // " + columnWidth + " : " + winWidth + " / " + winHeight);

        project_grid.find('.project_grid-item').each(function() {
            var $item = $(this),
                width = columnWidth,
                height = columnWidth;
            $item.css({ width: width, height: height });
        });

        // w-4 h-2 >> image_gross_quer
        project_grid.find('.w-4.h-2').each(function() {
            var $item = $(this),
                width = columnWidth*4,
                height = columnWidth*2;
            $item.css({ width: width, height: height});
        });

        // w-2 h-1 >> image_klein_quer
        project_grid.find('.w-2.h-1').each(function() {
            var $item = $(this),
                width = columnWidth*2,
                height = columnWidth*1;
            $item.css({ width: width, height: height });
        });


        if(winWidth < 768)
        {
            project_grid.find('.w-2.h-1').each(function() {
                var $item = $(this),
                    width = columnWidth*2,
                    height = columnWidth*2;
                $item.css({ width: width, height: height });
            });
        }

        // w-2 h-2 >> image_quadrat
        project_grid.find('.w-2.h-2').each(function() {
            var $item = $(this),
                width = columnWidth*2,
                height = columnWidth*2;
            $item.css({ width: width, height: height });
        });

        // w-2 h-3 >> image_gross_hoch
        project_grid.find('.w-2.h-3').each(function() {
            var $item = $(this),
                width = columnWidth*2,
                height = columnWidth*3;
            $item.css({ width: width, height: height });
        });

        if(winWidth < 768)
        {
            project_grid.find('.w-2.h-3').each(function() {
                var $item = $(this),
                    width = columnWidth*2,
                    height = columnWidth*2;
                $item.css({ width: width, height: height });
            });
        }

        iso.arrange({
            masonry: {
                columnWidth: columnWidth,
                isFitWidth: true
            }
        });


        // scale isotopy images
        $( ".project_grid-item" ).each( function( index, element ){

            var gridImage   = $(".project_grid_item_image img", $(this));


            // set width 100%
            gridImage.css("width", "100%");
            gridImage.css("height", "auto");

            var gridItemWidth = $(this).width();
            var gridItemHeight = $(this).height();


            var imgWidth    = Math.round( gridImage.width() );
            var imgHeight   = Math.round( gridImage.height() );


            // update img height
            if(gridItemHeight > imgHeight)
            {
                gridImage.css("height", "100%");
                gridImage.css("width", "auto");

                // update margin left
                var offset = (gridImage.width() - gridItemWidth) / 2;
                gridImage.css("margin-left", -offset + "px");

                // update margin top
                gridImage.css("margin-top", 0 + "px");


                //$(".project_grid_item_debug_text", $(this)).html(index + " : A : "  + gridItemWidth + " / " + gridItemHeight + " <br /> " + imgWidth + " / " + imgHeight);

            }
            else
            {
                gridImage.css("width", "100%");
                gridImage.css("height", "auto");

                // update margin left
                gridImage.css("margin-left", 0 + "px");

                // update margin top
                var offset = (gridImage.height() - gridItemHeight) / 2;
                gridImage.css("margin-top", -offset + "px");


                ///$(".project_grid_item_debug_text", $(this)).html(index + " : B : "  + gridItemWidth + " / " + gridItemHeight + " <br /> " + imgWidth + " / " + imgHeight);
            }

            iso.layout()
        });


        timeout = setTimeout(function()
        {
           // iso.layout();

        }, 500);
    });
    //$(window).resize();

    // -------------------------------------------
    function getUrlParameter(sParam)
    {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

    function isTouchDevice()
    {
        return 'ontouchstart' in document.documentElement;
    }
}

module.exports = homeModul;
