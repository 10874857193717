var Swiper = require('swiper/js/swiper');

function projectsModul()
{
    'use strict';

    var mySwiper;
    var urlParamater;
    var slidesCount;
    var timeout;
    var timeout2;

    // -------------------------------------------
    this.init = function()
    {
        console.log("projects.init()");

        initListener();

        initSwiper();

        checkDeeplink();
    };

    // -------------------------------------------
    function initListener()
    {
        //console.log("projects.initListener()");

        /*$(".project_filter_button").click(function()
        {
            var filterValue = $( this ).attr('data-filter');
        });*/

        $('.scroll_button').click(function(e)
        {
            $('html,body').scrollTo('#projects_text', 800);

            $('.scroll_button').addClass("hide");

            e.preventDefault();
        });
    }

    function adjustSwiperHeight() {
        let maxHeight = 0;

        $(".swiper-slide img").each(function () {
            maxHeight = Math.max(maxHeight, $(this).height());
        });

        $(".swiper-container").css("height", maxHeight + "px");
    }

    // -------------------------------------------
    function initSwiper()
    {
        //console.log("projects.initSwiper()");

        mySwiper = new Swiper('.swiper-container', {
            mode: 'horizontal',
            slidesPerView: 'auto',
            spaceBetween: 0,
            centeredSlides : true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            watchSlidesProgress: true,
            observer: true,
            observeParents: true,
        });

        adjustSwiperHeight();

        slidesCount = mySwiper.slides.length;
        if(slidesCount == 1)
        {
            $(".swiper-slide").addClass("swiper-slide-single");

            // offset top
            var sliderHeight = $(".swiper-slide").height();
            var imageHeight = $(".swiper-slide img").height();
            var offsetY = (sliderHeight - imageHeight) / 2;
            $(".swiper-slide img").css("margin-top", offsetY+"px");

            $(".swiper-button-next, .swiper-button-prev, .swiper-pagination").css("display", "none");
        }
        else
        {
            $(".swiper-slide").removeClass("swiper-slide-single");
            $(".swiper-slide img").css("margin-top", "0px");
        }

        clearTimeout(timeout);
        timeout = setTimeout(function()
        {
            $(window).resize();

        }, 1000);


        return;

       /* mySwiper = new Swiper('.swiper-container', {
            autoHeight: true,
            mousewheel: true,
            slidesPerView: 'auto',
            freeMode: true,
            freeModeSticky: true,
            loop: true,
            onSlideChangeEnd:function(swipe){
                console.log(geSlideDataIndex(swipe))
            },

            on: {
                init: function () {

                    var $slideActive = $('.swiper-slide-active');
                    console.log( "init() : " +  getActiveIndex() + " / " + $('.swiper-slide-active').data("project-name")  );
                }
            },
        });*/
    }


    // -------------------------------------------
    function checkDeeplink()
    {
        urlParamater = getUrlParameter("f");

        console.log("urlParamater = " + urlParamater);

        // reset highlight
        $(".project_filter_button").removeClass("active");

        // set highlight
        var filterValue = "";
        if(urlParamater != "" && urlParamater != undefined)
        {
            filterValue = String( urlParamater );
        }

        $("[data-filter='"+filterValue+"']").addClass('active');


        // update back_link
        var url = $(".back_link").data("url");

        console.log(">>> PROJECTS : filterValue = " + filterValue);


        if(filterValue != "")
        {
            $(".back_link").attr("href", url+"?f="+filterValue );
        }
        else
        {
            $(".back_link").attr("href", url);
        }




    }

    // -------------------------------------------
    $(window).on('resize', function()
    {
        /*$( ".swiper-slide" ).each( function( index, element ){

            var swiperHeight = $(".swiper-container").height();
            var imgHeight = $("img", $(this)).height();
            var imgWidth = $("img", $(this)).width();

            $(this).css("width", imgWidth+"px");
        });*/

        /*$(".swiper-container").each(function () {
            let maxHeight = 0;

            $(".swiper-slide img", $(this)).each(function () {
                maxHeight = Math.max(maxHeight, $(this).height());
            });

            $(this).css("height", maxHeight + "px");
        });*/

        mySwiper.update();

        clearTimeout(timeout2);
        timeout2 = setTimeout(function()
        {
            mySwiper.update();

        }, 500);

    });

    // -------------------------------------------
    $(window).on('scroll', function()
    {
        $('.scroll_button').addClass("hide");
    });




    // -------------------------------------------
    function getUrlParameter(sParam)
    {
        var sPageURL = window.location.search.substring(1),
            sURLVariables = sPageURL.split('&'),
            sParameterName,
            i;

        for (i = 0; i < sURLVariables.length; i++) {
            sParameterName = sURLVariables[i].split('=');

            if (sParameterName[0] === sParam) {
                return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
            }
        }
    };

}

module.exports = projectsModul;
